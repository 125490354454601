


















import { Component, Prop, Vue } from 'vue-property-decorator'
import { format } from 'quasar'
import { tasksStore } from '@/store'

const { capitalize } = format

@Component({
  name: 'TaskImportanceChip',
  components: {
    TaskImportanceIcon: () => import('./TaskImportanceIcon.vue'),
  },
})
export default class TaskImportanceChip extends Vue {
  /**
   * Task jid to use for getting importance value.
   * You may provide importance value directly through importance prop.
   * Providing a valid jid will override importance prop.
   */
  @Prop({ type: String, default: null }) readonly jid!: string | null

  /**
   * Custom importance value (not level) to use for creating a chip.
   * Will be ignored if jid is provided as well
   */
  @Prop({ type: Number, default: null }) readonly importance!: number | null

  /**
   * Color to use for chip background.
   */
  @Prop({ type: String, default: 'td-gray-1' }) readonly color!: string

  /**
   * Chip size. Quasar size value, or valid CSS size value.
   */
  @Prop({ type: String, default: 'sm' }) readonly size!: string

  /**
   * Attempt to show icon if at all possible.
   */
  @Prop({ type: Boolean, default: false }) readonly showIcon!: boolean

  /**
   * Will not work if no icon can be shown - we got to show at least something!
   */
  @Prop({ type: Boolean, default: false }) readonly hideLabel!: boolean

  /**
   * Attempt to show extended label caption.
   * Will resort to a number on failed attempt.
   */
  @Prop({ type: Boolean, default: false }) readonly longLabel!: boolean

  /**
   * add single letter prefix before label caption
   * useful to differentiate several similar chips (e.g. importance
   * and complexity) when used together in a same component
   * will do nothing if long label is used
   */
  @Prop({ type: Boolean, default: false }) readonly usePrefix!: boolean

  get computedImportance (): number | null {
    return this.task?.importance ?? this.importance
  }

  get task (): TADA.Task | null {
    return this.jid ? tasksStore.state.data[this.jid] ?? null : null
  }

  get computedShowIcon (): boolean {
    return (
      this.showIcon &&
      tasksStore.getters.importanceUseIcons &&
      this.computedImportance !== null
    )
  }

  get importanceLevel (): number | null {
    const i = this.computedImportance
    return i ? tasksStore.getters.importanceOptions.indexOf(i) : null
  }

  get label (): string | null {
    if (
      this.hideLabel &&
      this.computedShowIcon
    ) return null

    if (
      !this.longLabel ||
      this.importanceLevel === null ||
      !tasksStore.getters.importanceUseIcons
    ) return this.getDefaultLabel()

    const levelCaptions = ['lowest', 'low', 'medium', 'high', 'highest']
    const c = levelCaptions[this.importanceLevel]
    if (!c) return this.getDefaultLabel()

    return capitalize(this.$t('glossary.importance').toString())
  }

  getDefaultLabel (): string {
    return tasksStore.getters.importanceLabel(
      this.computedImportance,
      this.usePrefix,
    )
  }
}
